<script setup lang="ts">
const props = defineProps<{
  to?: string;
  class?: string;
  disabled?: boolean;
}>();

const classes = computed(() =>
  cn(
    "w-full p-5 gap-2.5 flex flex-col relative focus-within:z-20 border hover:bg-color-0 dark:hover:bg-color-2 rounded-xl",
    "group/item transition focus-within:border-strong hover:border-strong",
    props.class
  )
);
</script>

<template>
  <li class="flex items-stretch">
    <NuxtLink v-if="!to || !disabled" :to :class="classes">
      <slot />
    </NuxtLink>
    <div v-else :class="classes">
      <slot />
    </div>
  </li>
</template>
